<template>
  <div id="filter-custom">
    <h3
      class="header"
    >
      Bộ lọc
    </h3>
    <b-row>
      <!-- Người lao động -->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group>
          <label
            for="basicInput"
          >Quốc gia <span class="required"></span></label>
          <v-select
            v-model="isOverseaa"
            :reduce="label => label.id"
            label="name"
            :options="isOversea"
            :placeholder="'Quốc gia'"
            @input="filterDistrict"
          >
            <template v-slot:no-options="{ searching }">
              <template v-if="searching">
                Không có kết quả.
              </template>
              <em
                v-else
              >Không có dữ liệu</em>
            </template>
          </v-select>
        </b-form-group>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constansts/ConstanstApi'

export default {
  components: {

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VSelect,

  },
  data() {
    return {
      isOverseaa: '',
      isOversea: [ // Lao động trong nước hay ngoài nước
      ],
    }
  },
  created() {
    this.fetchCombobox()
  },
  methods: {
    async fetchCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBO)
      this.isOversea = data
    },
    filterDistrict() {
      this.$emit('filterDistrict', this.isOverseaa)
    },
  },
}
</script>

<style lang="scss">
#filter-custom{
  .header{
    font-size: 18px;
    color: #181F28;
  }
}
</style>
